import styles from "./index.module.scss";

import { Unity, UnityConfig, useUnityContext } from "react-unity-webgl";
import classNames from "classnames";

import { isMobile } from "react-device-detect";
import { ReactNode, useEffect, useRef } from "react";
import { ImageButton } from "../Buttons";
import { useImmer } from "use-immer";

export const Preview = ({
  href,
  titleSrc,
  cardSrc,
  subtitle,
}: {
  href: string;
  titleSrc: string;
  cardSrc: string;
  subtitle: string;
}) => {
  return (
    <div className={`${styles.gameContainer} ${styles.previewContainer}`}>
      <div className={styles.gameCard}>
        <img src={cardSrc} className={styles.bg} />
        <div className={styles.content}>
          <div className={styles.title}>
            <img
              src={titleSrc}
              alt="Game Logo"
              className={classNames(styles.logo, {
                [styles.small]:
                  titleSrc ===
                  "/assets/images/games/land_slide/logo_land_slide.png",
                [styles.verySmall]:
                  titleSrc === "/assets/images/games/cozy_verse/logo.png",
              })}
            />
            <div className={classNames(styles.subtitle, styles.preview)}>
              <p>{subtitle}</p>
            </div>
          </div>
          <>
            <div className={styles.actions}>
              <ImageButton
                className={styles.play}
                img={"/assets/images/games/shared/buttons/btn_play.png"}
                onMouseDown={() => {
                  window.location.assign(href);
                }}
              />
              <ImageButton
                className={styles.learnMore}
                img="/assets/images/home/4/Blue Rounded Rectangle.png"
                text={"Learn More"}
                onMouseDown={() => {
                  window.location.assign(href);
                }}
              />
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export const GameCard = ({
  titleSrc,
  subtitle,
  buttons,
  showGame,
  setState,
  playableOnBrowser = true,
}: {
  titleSrc: string;
  subtitle: string;
  buttons: ReactNode[];
  showGame: boolean;
  setState;
  playableOnBrowser?: boolean;
}) => {
  return (
    <div
      className={classNames(styles.gameCard, {
        [styles.gameStarted]: showGame,
      })}
    >
      <img
        src="/assets/images/games/shared/game_card.png"
        className={styles.bg}
        style={{
          display: !playableOnBrowser && "none",
        }}
      />
      <div className={styles.content}>
        <div className={styles.title}>
          <img
            src={titleSrc}
            alt="Game Logo"
            className={classNames(styles.logo, {
              [styles.small]:
                titleSrc ===
                "/assets/images/games/land_slide/logo_land_slide.png",
              [styles.verySmall]:
                titleSrc === "/assets/images/games/cozy_verse/logo.png",
            })}
          />
          <div
            className={classNames(styles.subtitle, {
              [styles.preview]: !playableOnBrowser,
            })}
          >
            <p>{subtitle}</p>
          </div>
        </div>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              minWidth: "250px",
            }}
          >
            {buttons}
          </div>
          <img
            className={styles.iconDown}
            src="/assets/images/games/shared/icon_down.png"
          />
          <div
            className={styles.playButton}
            onClick={() => {
              setState((state) => {
                state.showGame = true;
              });
            }}
            style={{
              display: !playableOnBrowser && "none",
            }}
          >
            <img src="/assets/images/games/land_slide/icon_play.png" />
          </div>
        </>
      </div>
    </div>
  );
};

export const Game = ({
  titleSrc,
  subtitle,
  unityConfig,
  buttons,
  thirdweb = false,
}: {
  titleSrc: string;
  subtitle: string;
  unityConfig: UnityConfig;
  buttons: ReactNode[];
  thirdweb?: boolean;
}) => {
  const { unityProvider, isLoaded, unload, requestFullscreen, sendMessage } =
    useUnityContext(unityConfig);

  const handleClickFullscreen = () => {
    if (isLoaded === false) {
      return;
    }
    requestFullscreen(true);
  };

  const [state, setState] = useImmer<{
    showGame: boolean;
  }>({
    showGame: false,
  });

  const unityRef = useRef();

  useEffect(() => {
    if (thirdweb) {
      const script = document.createElement("script");
      script.src = "/assets/build/cozyverse/lib/thirdweb-unity-bridge.js";
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    const muteUnity = () => {
      const unity = unityRef.current;
      if (unity && isLoaded) {
        const computedStyle = getComputedStyle(unity);
        const displayValue = computedStyle.getPropertyValue("display");
        if (displayValue === "none") {
          sendMessage("AudioController", "StopMusic");
        } else {
          sendMessage("AudioController", "PlayMusic");
        }
      }
    };
    muteUnity();
    window.addEventListener("resize", muteUnity);

    // Anything in here is fired on component mount.
    return () => {
      unload()
        .then(() => console.log("game unmounted"))
        .catch((e) => console.error(e));
      window.removeEventListener("resize", muteUnity);
    };
  }, [isLoaded]);

  const gameCard = (
    <GameCard
      titleSrc={titleSrc}
      subtitle={subtitle}
      buttons={buttons}
      setState={setState}
      showGame={state.showGame}
    />
  );

  let body;
  if (isMobile || !state.showGame) {
    body = gameCard;
  } else {
    const unity = (
      <Unity
        ref={unityRef}
        unityProvider={unityProvider}
        className={classNames(styles.unityScreen, {
          [styles.hide]: !state.showGame || !isLoaded,
        })}
      />
    );

    body = (
      <>
        <img
          src="/assets/images/games/shared/game_card.png"
          className={styles.placeholder}
        />
        {unity}
        {gameCard}
        <div className={styles.fullScreen} onClick={handleClickFullscreen} />
      </>
    );
  }

  return (
    <div className={styles.gameContainer}>
      {body}
      <Help />
    </div>
  );
};

const Help = () => {
  return (
    <div
      className={classNames(styles.help, {
        [styles.mobile]: isMobile,
      })}
    >
      <div className={styles.body}>
        <img src="/assets/images/games/shared/icon_help.png" />
        <div className={styles.text}>
          {isMobile
            ? "Mobile browser not supported"
            : "Browser width does not meet the minimum width to play (960px)"}
        </div>
      </div>
    </div>
  );
};

export const Description = ({ children }) => {
  return <div className={styles.description}>{children}</div>;
};

export const enum AppStoreType {
  apple = "apple",
  android = "android",
}

export const AppStoreButton = ({
  href,
  appStore,
  className,
  active = true,
}: {
  href?: string;
  appStore: AppStoreType;
  className?: string;
  active?: boolean;
}) => {
  return (
    <ImageButton
      img={`/assets/images/games/shared/buttons/btn_${appStore}.png`}
      onMouseDown={() => {
        window.open(href, "_blank");
      }}
      disabled={!active}
      className={className}
    />
  );
};

export const AppStoreInfo = ({
  iconImg,
  buttons,
}: {
  iconImg: string;
  buttons: ReactNode[];
}) => {
  return (
    <div className={styles.appStoreInfo}>
      <img src={iconImg} />
      <div className={styles.buttons}>{buttons}</div>
    </div>
  );
};

export const Thumbnails = ({ children }) => {
  return <div className={styles.thumbnails}>{children}</div>;
};

export const ThumbnailsPortrait = ({ children }) => {
  return <div className={styles.thumbnailsPortrait}>{children}</div>;
};
