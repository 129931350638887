import Section from "../../../components/Section";
import Avatar from "../../../components/Avatars";
import {
  AppStoreButton,
  AppStoreInfo,
  AppStoreType,
  Description,
  Game,
  GameCard,
  ThumbnailsPortrait,
} from "../../../components/GamePage";
import { useImmer } from "use-immer";
import styles from "./index.module.scss";
import { ReactNode } from "react";
import { Helmet } from "react-helmet";

const Subtitle = "Idle RPG";

const Buttons = [
  <AppStoreButton
    href="https://apps.apple.com/us/app/fishin-friends-idle-rpg/id6449044516"
    appStore={AppStoreType.apple}
  />,
  <AppStoreButton
    href="https://play.google.com/store/apps/details?id=com.CozyLabs.CozyWalk&hl=en_US"
    appStore={AppStoreType.android}
  />,
];

const FishinFriendsHero = ({ children }: { children? }) => {
  const [state, setState] = useImmer<{
    showGame: boolean;
  }>({
    showGame: false,
  });

  return (
    <Section className={styles.hero}>
      <Helmet>
        <title>Cozyverse | Fishin' Friends</title>
        <meta name="apple-itunes-app" content="app-id=6450430184" />
      </Helmet>
      <div className={styles.gameContainer}>
        <GameCard
          titleSrc={
            "/assets/images/games/fish-n-friends/logo_fishin_friends.png"
          }
          subtitle={Subtitle}
          buttons={Buttons}
          setState={setState}
          showGame={false}
          playableOnBrowser={false}
        />
      </div>
      {children}
      <Avatar
        img="/assets/images/home/fish-n-friends/cozy.png"
        className={styles.cozy}
      />
      <Avatar
        img="/assets/images/home/fish-n-friends/bottom.png"
        className={styles.snowfront}
      />
    </Section>
  );
};

const FishinFriendsPage = () => {
  return (
    <div className={styles.root}>
      <FishinFriendsHero />
      <Section className={styles.about}>
        <Description>
          <p>
            Join Cozy the Penguin in "Fishin' Friends" the ultimate idle fishing
            adventure game! Relax as you and Cozy explore Cozy Reef, embark on
            idle fishing expeditions, and catch dozens of adorable fish. Level
            up to collect rare species and help Cozy uncover the secrets of the
            guardian of the Reef. Visit mysterious traders, find magical
            treasures, and see what lies beyond the horizon.
          </p>
          <ul>
            <li>
              Catch and Collect: Catch dozens of unique fish, level up your
              fishing skills, complete your collection, and enjoy the thrill of
              discovering rare species.
            </li>
            <li>
              Idle Expeditions: Send Cozy on idle fishing expeditions that fit
              your schedule—progress while offline in this relaxing,
              family-friendly game!
            </li>
            <li>
              Farming and Trading: Grow food on your farm, use it as bait or
              sell it, and upgrade your farm to increase your harvest.
            </li>
            <li>
              Customize and Upgrade: Unlock adorable costumes for Cozy, upgrade
              your equipment, and customize your home with your own furniture
              and treasures.
            </li>
            <li>
              Puzzles and Quests: Solve unique puzzles, encounter hidden
              secrets, and unlock new expeditions as you progress through Cozy's
              journey.
            </li>
          </ul>
        </Description>
        <ThumbnailsPortrait>
          <img
            src="/assets/images/games/fish-n-friends/1.png"
            style={{
              scale: "0.9",
            }}
          />
          <img
            src="/assets/images/games/fish-n-friends/2.png"
            style={{
              scale: "0.9",
            }}
          />
          <img
            src="/assets/images/games/fish-n-friends/4.png"
            style={{
              scale: "0.9",
            }}
          />
        </ThumbnailsPortrait>
        <Avatar
          img="/assets/images/games/shared/ducks.png"
          hideMobile
          style={{
            left: "-15rem",
            bottom: "-10rem",
          }}
        />
        <Avatar
          img="/assets/images/games/shared/ducks.png"
          hideMobile
          style={{
            right: "-15rem",
            top: "-10rem",
          }}
        />
      </Section>
    </div>
  );
};

export { FishinFriendsPage, FishinFriendsHero };
